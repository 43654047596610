<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <service-filter
          :action-methods="searchData"
          :action-filter="filterData"
          :download-excel="downloadExcel"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <loading v-if="dataLoading" />
      <b-card
        v-else
        no-body
      >
        <counters />
        <service-table :remove-data="removeData" />
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardFooter,
  BCol,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import ServiceFilter from '@/views/Service/Index/ServiceFilter.vue'
import Counters from '@/views/Service/Index/Counters.vue'
import ServiceTable from '@/views/Service/Index/ServiceTable.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'ServiceIndex',
  components: {
    Loading,
    BCard,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    ServiceFilter,
    Counters,
    ServiceTable,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      dataQuery: {
        select: [
          'com_service_tracing.id                   AS id',
          'com_service_tracing.id_com_service_type  AS id_com_service_type',
          'com_service_tracing.appointment_date AS appointment_date',
          'com_service_tracing.appointment_time AS appointment_time',
          'com_customer.name                    AS customer',
          'com_customer.company_name            AS company',
          'com_brand.name                       AS brand',
          'car_brand.name                       AS car_brand',
          'car_model.title                      AS car_model',
          'car_swap_brand.name                  AS car_swap_brand',
          'car_swap_model.title                 AS car_swap_model',
          'com_cars.myear                       AS myear',
          'com_cars.license_plate               AS license_plate',
          'com_cars.chassis                     AS chassis',
          '0                                    AS service_type',
          'com_service_status.title             AS service_status',
          'com_service_status.styleclass        AS styleclass',
          'com_user.name                        AS username',
          'com_meet.title                       AS meet',
          'com_location.title                   AS location',
        ],
        order_by: ['com_service_tracing.appointment_date,com_service_tracing.appointment_time', 'ASC'],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataLoading() {
      return this.$store.getters['serviceTracing/getDataLoading']
    },
    dataCount() {
      return this.$store.getters['serviceTracing/getServicesCount']
    },
    filterDataParams() {
      return this.$store.getters['serviceTracing/getFilterData']
    },
    quickSearch() {
      return this.$store.getters['serviceTracing/getQuickSearch']
    },
    downloadExcel() {
      const urlParams = []
      if (this.filterDataParams.sdate) {
        urlParams.push(`sdate=${this.filterDataParams.sdate}`)
      }
      if (this.filterDataParams.edate) {
        urlParams.push(`edate=${this.filterDataParams.edate}`)
      }
      if (this.filterDataParams.id_com_brand) {
        this.filterDataParams.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterDataParams.id_com_service_status) {
        this.filterDataParams.id_com_service_status.forEach(e => {
          urlParams.push(`id_com_service_status[]=${e}`)
        })
      }
      if (this.filterDataParams.id_com_location) {
        this.filterDataParams.id_com_location.forEach(e => {
          urlParams.push(`id_com_location[]=${e}`)
        })
      }
      if (this.filterDataParams.id_com_user) {
        this.filterDataParams.id_com_user.forEach(e => {
          urlParams.push(`id_com_user[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/data/service_tracing/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.filterData()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_service_tracing.id': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_customer.phone': this.quickSearch.keyword,
          'com_cars.license_plate': this.quickSearch.keyword,
        }
        this.getDataList()
      } else {
        this.dataQuery.or_like = {}
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    filterData() {
      const params = this.filterDataParams
      const where = {}
      const orWhereIn = []
      if (params.sdate) {
        where['com_service_tracing.appointment_date >='] = params.sdate
        this.dataQuery.sdate = params.sdate
      } else {
        this.dataQuery.sdate = null
      }
      if (params.edate) {
        where['com_service_tracing.appointment_date <='] = params.edate
        this.dataQuery.edate = params.edate
      } else {
        this.dataQuery.edate = null
      }
      if (params.id_com_brand.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_brand', 1: params.id_com_brand })
      }
      if (params.id_com_service_status.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_service_status', 1: params.id_com_service_status })
      }
      if (params.id_com_user.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_user', 1: params.id_com_user })
      }
      if (params.id_com_location.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_location', 1: params.id_com_location })
      }
      if (params.id_com_service_type.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_service_type', 1: params.id_com_service_type })
      }
      if (params.id_com_open_user.length > 0) {
        orWhereIn.push({ 0: 'com_service_tracing.id_com_open_user', 1: params.id_com_open_user })
      }
      if (where) {
        this.dataQuery.where = where
      }
      if (orWhereIn.length > 0) {
        this.dataQuery.or_where_in = orWhereIn
      } else {
        this.dataQuery.or_where_in = []
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('serviceTracing/servicesList', this.dataQuery)
    },
    removeData(id) {
      this.$store.dispatch('serviceTracing/removeData', id)
        .then(res => {
          if (res.status) {
            this.getDataList()
          }
        })
    },
  },
}
</script>
